.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: black;
    color: gray;
    z-index: 10;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .footer-left,
  .footer-socials {
    display: flex;
    gap: 5px;
  }
.footer-left{
    flex-direction: column;
}  

.footer-socials{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    color: white;
    padding: 50px;
}

.footer-links{
    color: gray;
    text-align: left;
}
.footer-links a {
    text-decoration: none;
    color: inherit;
    background: none;
    border: none;
    outline: none;
}
.footer-links:hover{
    color: white;
}

.footer-left{
    padding: 50px;
}
.copy{
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100vw;
}
