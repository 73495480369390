.scene-card-manager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 100px;
    width: 100%;
    margin: auto;
}

.scene-card{
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    width: 300px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0.7;
    cursor: pointer;
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
}
.scene-card-carousel {
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0.7;
    cursor: pointer;
    border: 2px gray solid;
}
.scene-card:hover {
    opacity: 1;
    transform: scale(1.2);
}
