.headline-card-manager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 100px;
    width: 100%;
    margin: auto;
}

.headline-card {
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    width: 300px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0.7;
    cursor: pointer;
}

.headline-card:hover {
    opacity: 1;
}

.hover-video {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

