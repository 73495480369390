.scene-info-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    color: white;
    margin-top: 40px;
}

.scene-info-left {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.scene-info-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.scene-info-logo {
    flex: 1;
    align-self: flex-start;
}

.scene-info-logo img{
    object-fit: contain;
}

.scene-info-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40%;
    background: radial-gradient(
     circle,
     rgba(96, 136, 44, 0.2) 0%,
     rgba(27, 78, 221, 0.2) 100%
   );   
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid gray;
}
.scene-info-title-container{
    display: flex;

}
.scene-info-text-container {
    text-align: left;
    line-height: 1.5;
}
.scene-info-text-container p {
    white-space: pre-wrap;
  }
  

.scene-info-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.scene-info-master-image {
    width: 100%;
    height: 500px;
    overflow: hidden; 
    border-radius: 8px; 
}

.scene-info-master-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; 
}


.scene-info-mini-images {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-around;
}

.scene-info-mini-images img {
    width: 48%;
    height: auto;
    border-radius: 25px;
}

.scene-jump-in-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.jump-in-button {
    padding: 10px 80px;
    font-size: 1.2em;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(8,9,10,1) 33%, rgba(82,82,82,1) 100%);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid grey;
}

.jump-in-button:hover {
    transform: translateY(-2px);
}
