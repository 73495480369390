.settings-modal {
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    width: 30vw;             
    height: 450px;
    margin: 25vh auto;
    display: flex;          
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1;
  }
  
  .settings-modal::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 25px;
    border: 2px solid transparent;
    background: linear-gradient(
      65deg,
      var(--primary-color),
      var(--secondary-color),
      var(--highlight-color),
      var(--error-color) 40%,
      var(--accent-color),
      var(--primary-color) 60%
    ) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
    z-index: 1;
  }
  .settings-modal {
    background-color: rgba(34, 36, 48, 0.7);
  }
  .settings {
    flex: 1;
    width: 100%;            
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10;
    align-items: center;
    position: relative;
    z-index: 2;
    color: white;
    box-sizing: border-box;
  }
  .settings h2 h3 {
    margin: 0;
  }

.settings-section{
    display: flex;
    flex-direction: column;
    width: 80%;
    text-align: center;
}

.open-settings-button{
    position: absolute;
    right: 10px;
    top: 85px;
    height: 60px;
    width: 60px;
    background-color: var(--highlight-color);
    color: var(--primary-color) ;
    border: var(--primary-color) 1px solid;
    border-radius: 50%;
    margin: 10px;
    display: flex; 
    justify-content: center; 
    align-items: center;
    cursor: pointer;
    position: absolute;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    transform-origin: center;
    height: 6px;
    background: transparent;
    margin: 10px 0;
    cursor: pointer;
  }
  
  .slider::-webkit-slider-runnable-track {
    height: 6px;
    background: var(--primary-color);
    border-radius: 3px;
  }
  .slider::-moz-range-track {
    height: 6px;
    background: var(--primary-color);
    border-radius: 3px;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    background: var(--accent-color);
    border-radius: 50%;
    border: 2px solid var(--highlight-color);
    margin-top: -4px;
    transition: background 0.3s ease;
  }
  .slider::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background: var(--accent-color);
    border-radius: 50%;
    border: 2px solid var(--highlight-color);
    transition: background 0.3s ease;
  }
  
  .slider:hover::-webkit-slider-thumb,
  .slider:focus::-webkit-slider-thumb {
    background: var(--error-color);
  }
  .slider:hover::-moz-range-thumb,
  .slider:focus::-moz-range-thumb {
    background: var(--error-color);
  }

.settings-button{
    padding: 10px;
    background-color: var(--highlight-color);
    color: var(--primary-color) ;
    border: var(--primary-color) 1px solid;
    border-radius: 5px;
    cursor: pointer;
}
  